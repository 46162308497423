import React, { useState } from 'react'
import '../deal.css';
import Header1 from '../Header1'
import Footer from '../Footer'
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import {Container} from 'react-bootstrap';
import '../Blog/Blognav.js';




function Pogopass() {

  const [date,setdate]=useState(Date);
  // console.log(date.format());
  return (
    <>
    {/* <Header1 /> */}
<Container>
      <div className="myrow" style={{height:"100%",paddingBottom:"20%"}}>
        <Link to="/">Home </Link> {'>'} <Link to="/blog">Blog </Link> {'>'} POGO PASS
        <div className="content" >         
            <div className="leftcolumn" >         

              <div className="dealsdata" >
              <Card style={{ width: '50rem' }}> 
              <Card.Body style={{padding: "10px"}}>
                  <Card.Title>POGO PASS = ONE PASS.ONE PRICE. ONE YEAR OF FUN!</Card.Title>
                  {/* <Card.Text>Last updated {new Date(date).toDateString()}</Card.Text> */}
                  <Card.Text> June 1 2024</Card.Text>
                 
                  <Card.Text></Card.Text>
                  {/* <Button variant="dark">Read More</Button> */}
                </Card.Body>
                <Card.Img variant="top" src="/pogopass.jfif" style={{alignSelf:"center",height:"50%",width:"50%"}}/>
                <Card.Body style={{padding: "10px"}}>
                  <Card.Text></Card.Text>
                  <Card.Text>With kids out of school, families often look for affordable ways to keep them entertained.   &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;
                    <b>   Pogo Pass </b>offers a variety of activities 
                    at a discounted rate, which can save families a significant amount of money.</Card.Text>
                    <Card.Text> <b>Pogo Pass</b> is a membership program that offers
                    a convenient and economical way to access a variety of local attractions, making it an excellent option for families and individuals
                     looking to explore their city and enjoy diverse activities without the high costs typically associated with frequent outings.
                     
                      {/* <Link to="https://amzn.to/3LT5Vog">gets 1-year of GrubHub
                      Plus for free</Link> and <Link to="https://amzn.to/3Q9ncMs" target='_blank' rel="noreferrer">check out all invite only deals</Link> one of the best deal in invite only deals 
                      is <Link to="https://amzn.to/45nwER0" target='_blank' rel="noreferrer">Amazon Fire TV 50" Omni Series 4K UHD smart TV for just $149+ (reg.$375+)</Link> */}
                      
                      </Card.Text>

                        {/* <Card.Text>you can checkout our all amazon deals <Link>here</Link></Card.Text> */}
                      <Card.Text>With <b>Pogo Pass</b>, you can enjoy significant savings compared to purchasing individual tickets for each attraction.</Card.Text>
                      <Card.Text>Snag this deal before prices increase!!</Card.Text>
                      <Card.Text> Just click the link <Link to="https://www.pogopass.com?affiliate_code=dinodeal" target='_blank' rel="noreferrer">https://www.pogopass.com?affiliate_code=dinodeal</Link> and this pass gets you a full year of
                       freebies from all of Pogo Pass venues. 
                        The regular price on a Pogo Pass is $99.98 per year, but with the DinoDealz link it’s 50% off, for a total of $49.99 per pass. </Card.Text>

                        <Card.Text>Once purchased, the passes are good for one full calendar year.
                             Pogo Pass has a smartphone app that allows you to store all of your pass information on your phone for easy entry at all of the Pogo Pass venues.</Card.Text>

                        <Card.Text style={{fontSize:"10px"}}>As a Pogo Pass partner, we may receive a small commission on qualified purchases.</Card.Text>

                     
                
                 
                </Card.Body>
               
              </Card>       
               
               
                    
                   
                
              </div>
            
            </div>       
            

          <div className="rightcolumn"> 
         
         <div className='rightdata'>
          {/* <button className="btnright" onClick={goSearch}  name="prime" >Early amazon prime day deals</button> */}
          {/* <a  href={'/search/'+'prime'} >early amazon prime day deals</a> */}
         {/* <hr ></hr> */}
         {/* <a  href={'/search/'+'prime'}  >trading deals</a> */}
         </div>
        
        
        </div>




      </div>
      </div>
</Container>
      {/* <Footer /> */}
    </>
  )
}

export default Pogopass