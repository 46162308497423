import React, { useState } from 'react'
import '../deal.css';
import Header1 from '../Header1'
import Footer from '../Footer'
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import {Container} from 'react-bootstrap';
import '../Blog/Blognav.js';




function Prime() {

  const [date,setdate]=useState(Date);
  // console.log(date.format());
  return (
    <>
    {/* <Header1 /> */}
<Container>
      <div className="myrow" style={{height:"100%",paddingBottom:"20%"}}>
        <Link to="/">Home </Link> {'>'} <Link to="/blog">Blog </Link> {'>'} Amazon Prime
        <div className="content" >         
            <div className="leftcolumn" >         

              <div className="dealsdata" >
              <Card style={{ width: '50rem' }}> 
              <Card.Body style={{padding: "10px"}}>
                  <Card.Title>Amazon’s July prime day is here and we find these deals so far</Card.Title>
                  {/* <Card.Text>Last updated {new Date(date).toDateString()}</Card.Text> */}
                  <Card.Text> Tue Jul 16 2024</Card.Text>
                 
                  <Card.Text></Card.Text>
                  {/* <Button variant="dark">Read More</Button> */}
                </Card.Body>
                <Card.Img variant="top" src="/PrimeDay.png" style={{alignSelf:"center"}}/>
                <Card.Body style={{padding: "10px"}}>
                  <Card.Text></Card.Text>
                  <Card.Text>The highly anticipated Amazon Prime Big Deal Days event is here. Scheduled for July 16 and July 17,
                     this offer a prime members a early access of Black Friday. </Card.Text>
                     <Card.Text>An Amazon <Link to="https://amzn.to/4eY2auV" target='_blank' rel="noreferrer"> Prime account</Link> may be required to secure the deal prices below.</Card.Text>
                    <Card.Text> One of the best primeday deal comes ahead of the event is GrubHub.Amazon Prime memebers <Link to="https://amzn.to/3LT5Vog" target='_blank' rel="noreferrer">gets 1-year of GrubHub
                      Plus for free</Link> and <Link to="https://amzn.to/3Q9ncMs" target='_blank' rel="noreferrer">check out all prime day deals</Link><br></br>
                       {/* one of the best deal in invite only deals  */}
                      {/* is <Link to="https://amzn.to/45nwER0" target='_blank' rel="noreferrer">Amazon Fire TV 50" Omni Series 4K UHD smart TV for just $149+ (reg.$375+)</Link>*/}
                      </Card.Text> 

                        {/* <Card.Text>you can checkout our all amazon deals <Link>here</Link></Card.Text> */}
                      <Card.Text>These Are The Quick Link For Best Amazon Prime Day Deals</Card.Text>

                     
                <ListGroup className="list-group-flush">
                <ListGroup.Item> All-new Echo Show 5 (3rd Gen, 2023 release) <Link to="https://amzn.to/48LlR5S" target='_blank' rel="noreferrer">$49.99 (reg.$89.99)</Link></ListGroup.Item>
                <ListGroup.Item> Kindle Kids <Link to="https://amzn.to/3rGEEPd"  target='_blank' rel="noreferrer"> $99.99 (reg.$119.99)</Link></ListGroup.Item>
                <ListGroup.Item>TIKI Brand Smokeless  <Link to="https://amzn.to/3rSZ87c"  target='_blank' rel="noreferrer"> 24.75 in. Patio Fire Pit $279 (reg.395)  </Link> <br></br> 
                <Link to="https://amzn.to/3zHxm1l"  target='_blank' rel="noreferrer">TIKI Brand Smokeless 20 Inch Social Fire Pit $162 (Reg. $250)  </Link></ListGroup.Item>
               
        {/* <ListGroup.Item>Adidas:<Link ></Link></ListGroup.Item> */}
        
        {/* <ListGroup.Item>ChromeBooks:<Link to="https://amzn.to/46ntspM" target='_blank' rel="noreferrer">Acer 2022 Chromebook 315 15.6" $229 (reg. $599)</Link> and 
        <Link to="https://amzn.to/46ntspM" target='_blank' rel="noreferrer">Samsung Chromebook 4 Chrome OS 11.6" $185 (reg.$229)</Link>
        </ListGroup.Item> */}
       
        <ListGroup.Item><b>Apple : </b><br></br>
        <Link to="https://amzn.to/4cMMJnF" target='_blank' rel="noreferrer">Apple 2024 MacBook Air 13-inch Laptop with M3 chip $849 (reg. $1099)</Link> <br></br>
        <Link to="https://amzn.to/3QaHKo4" target='_blank' rel="noreferrer">Apple iPad (9th generation) $249 (reg.$329)</Link> <br></br>      
        <Link to="https://www.amazon.com/dp/B0D3J9XDMQ?ascsubtag=F0401J2VY23ZHXS8ZWDKTVHNRA08G&smid=ATVPDKIKX0DER&linkCode=sl1&tag=dinodealz-20&linkId=e021fc6f047901cf47635fd4f9e0ca0c&language=en_US&ref_=as_li_ss_tl" target='_blank' rel="noreferrer">Apple iPad Pro 11-Inch 256GB $900 (reg.$999)</Link><br></br>
        <Link to="https://amzn.to/3QaHKo4" target='_blank' rel="noreferrer">Apple iPad (10th Generation) $279 (reg. $349)</Link> <br></br>
        <Link to="https://amzn.to/3rNgWAL" target='_blank' rel="noreferrer"> Airpods $69</Link> <br></br>
        <Link to="https://amzn.to/3W7jepv" target='_blank' rel="noreferrer">Apple AirPods Max Wireless Over-Ear Headphones $394 (reg. $549)</Link><br></br>
        <Link to="https://amzn.to/46b9bUX" target='_blank' rel="noreferrer"> Apple Watch Ultra 2 $699 (reg. $799)</Link><br></br>
        <Link to="https://amzn.to/3RScg7a" target='_blank' rel="noreferrer">Apple Watch SE (2nd Gen) $169 (reg. $249)</Link> </ListGroup.Item>
       
        <ListGroup.Item>LED & LCD TVs: <Link to="https://amzn.to/4bNt6e3" target='_blank' rel="noreferrer" > up to 47% off</Link></ListGroup.Item>
        
        {/* <ListGroup.Item>Amazon 50"  Omni Series 4K UHD smart TV for just $149+:<Link></Link> </ListGroup.Item> */}
       
        <ListGroup.Item><b>Laptops & monitor : </b> <br></br>
        <Link to="https://amzn.to/3LPqAJN" target='_blank' rel="noreferrer" >HP 2023 Newest 14" Laptop $379(reg. 498) </Link><br></br>
        <Link to="https://amzn.to/4f2AUv7" target='_blank' rel="noreferrer" >SAMSUNG 34" Odyssey G5 Ultra-Wide Gaming Monitor $299 (reg. $549)</Link><br></br>
        <Link to="https://amzn.to/4f2AUv7" target='_blank' rel="noreferrer" >ASUS 23.8” 1080P Monitor  $109 (reg. $149)</Link><br></br>
        <Link to="https://amzn.to/4bLoxkl" target='_blank' rel="noreferrer" >SAMSUNG 22" T350 Series FHD 1080p Computer Monitor $67 (reg. $119.99)</Link>
        </ListGroup.Item>
       
        {/* <ListGroup.Item>OLED TVs: <Link to="https://amzn.to/3tjv310" target='_blank' rel="noreferrer" >LG SONY And Samsung</Link></ListGroup.Item> */}
       
        {/* <ListGroup.Item>Amazon 50"  Omni Series 4K UHD smart TV for just $149+: <Link></Link></ListGroup.Item> */}
       
        <ListGroup.Item>ps5: <Link to="https://amzn.to/3PNb9mD" target='_blank' rel="noreferrer">PlayStation 5 Digital Edition $485</Link><br></br>
        <Link to="https://amzn.to/3PNb9mD" target='_blank' rel="noreferrer">Meta Quest 3 128GB $429 (reg. $499)</Link></ListGroup.Item>
       
        <ListGroup.Item><b>Robot vacuums: </b><br></br>
        <Link to="https://amzn.to/3WpehtG" target='_blank' rel="noreferrer" >roborock S8 Pro Ultra Robot Vacuum and Mop $949 (Reg. $1599)</Link><br></br>
        
        <Link to="https://amzn.to/3WpehtG" target='_blank' rel="noreferrer" >iRobot Roomba s9 $499 (reg. $999)</Link><br></br>
        <Link to="https://amzn.to/4bK9fMO" target='_blank' rel="noreferrer" > Shark AV2501S AI Ultra Robot Vacuum $249 (reg. $549)</Link>
        
         </ListGroup.Item>
       
        <ListGroup.Item>BISSELL Little Green Multi-Purpose Portable Carpet and Upholstery Cleaner <Link to="https://amzn.to/3Q80OTU"  target='_blank' rel="noreferrer" >$81 (reg.$123)</Link> </ListGroup.Item>
       
        <ListGroup.Item> Luggage from Wrangler, Kensie, and Traveler's Club <Link to="https://amzn.to/3Sc3qRb" target='_blank' rel="noreferrer">up to 70% off</Link> <br></br>
        <Link to="https://amzn.to/4bNWLDA" target='_blank' rel="noreferrer">Rockland Journey Softside Upright Luggage Set $82 (Reg. $219)</Link>
        </ListGroup.Item>
        <ListGroup.Item> Dyson Vacuums and Air Purifiers
          <Link to="https://amzn.to/3zKvEvY" target='_blank' rel="noreferrer"> up to 35% off</Link> </ListGroup.Item>
        <ListGroup.Item>LEGO Sets and Building Toys from Magna-Tiles, Tegu, and More <Link to="https://amzn.to/3ZRssHK" target='_blank' rel="noreferrer"> up to 44% off</Link><br></br> 
        <Link to="https://amzn.to/3LqVIyP" target='_blank' rel="noreferrer"> Lego Sets up to 30% off</Link><br></br>
        <Link to="https://amzn.to/3xNhVEg" target='_blank' rel="noreferrer"> PicassoTiles Magnetic Tiles up to 35% off</Link>
        </ListGroup.Item>
        <ListGroup.Item>Bose HeadPhone ,Speaker and Soundbar <Link to="https://amzn.to/46JD9yE" target='_blank' rel="noreferrer">up to 40% off</Link> and  
                <Link to="https://amzn.to/3ZMff37" target='_blank' rel="noreferrer">  Bose QuietComfort 45 Wireless Bluetooth Noise Cancelling Headphones $230(reg.329)
        </Link></ListGroup.Item>

        <ListGroup.Item>nespresso machine espresso <Link to="https://amzn.to/3W9iTCE" target='_blank' rel="noreferrer">Nespresso Vertuo $129 (reg.$199) </Link> <br></br>  
                <Link to="https://amzn.to/3LpqFDt" target='_blank' rel="noreferrer"> Nespresso Vertuo $99 (Reg. $149) </Link><br></br>
                <Link to="https://amzn.to/3WqmZYk " target='_blank' rel="noreferrer">   Check out All Coffee machines</Link>
                </ListGroup.Item>
        <ListGroup.Item>Instant Pot Duo 7-in-1 Electric Pressure Cooker <Link to="https://amzn.to/3PTUZIc" target='_blank' rel="noreferrer">
        $64.99 ( reg. 99.99) </Link>  
               </ListGroup.Item>

               {/* <ListGroup.Item>Thisworx car vacuum cleaner <Link to="https://amzn.to/3Fdu3y9" target='_blank' rel="noreferrer">
       $19.58 (reg.$39.99) </Link>  
               </ListGroup.Item> */}


      </ListGroup>
                 
                </Card.Body>
               
              </Card>       
               
               
                    
                   
                
              </div>
            
            </div>       
            

          <div className="rightcolumn"> 
         
         <div className='rightdata'>
          {/* <button className="btnright" onClick={goSearch}  name="prime" >Early amazon prime day deals</button> */}
          {/* <a  href={'/search/'+'prime'} >early amazon prime day deals</a> */}
         {/* <hr ></hr> */}
         {/* <a  href={'/search/'+'prime'}  >trading deals</a> */}
         </div>
        
        
        </div>




      </div>
      </div>
</Container>
      {/* <Footer /> */}
    </>
  )
}

export default Prime