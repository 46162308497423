import axios from "axios";
import { getToken } from "../Auth";

//  const DEALS_API_BASE_URL="http://localhost:8080/api";
//  const USER_API_BASE_URL="http://localhost:8080/"
 
  const DEALS_API_BASE_URL="https://dinodealz-backend-bcb469ab952a.herokuapp.com/api"
//  const DEALS_API_BASE_URL="http://localhost:8080/api";
//  const USER_API_BASE_URL="http://localhost:8080/"
   const USER_API_BASE_URL="https://dinodealz-backend-bcb469ab952a.herokuapp.com/"
// const DEALS_API_BASE_URL="http://localhost:8080/image";
class UserService{
    getURl(){
        return DEALS_API_BASE_URL;
    }
    getStoreDeals(store){
        return axios.get(DEALS_API_BASE_URL+"/deals_by_store/"+store);
    }
    getcategoryDeals(category){
        return axios.get(DEALS_API_BASE_URL+"/deals_by_category/"+category);
    }
    getCategory(){
        return axios.get(DEALS_API_BASE_URL+"/category/")
    }
    getSearchDeals(searchstring){
        console.log(searchstring)
        return axios.get(DEALS_API_BASE_URL+"/search/"+searchstring)
    }

    getStore(){
        return axios.get(DEALS_API_BASE_URL+"/store/")
    }
    // getDeals(){
    //     return axios.get(DEALS_API_BASE_URL+"/deals");
    // }
    getDeals(pagenumber,pagesize){
        return axios.get(DEALS_API_BASE_URL+"/deals/"+pagenumber+"/"+pagesize);
    }
    postContactUs(contactDetails){
        return axios.post(USER_API_BASE_URL+"api/contactus/add",contactDetails)
    }

    postSignUp(signupDetails){
        return axios.post("https://dinodealz-backend-bcb469ab952a.herokuapp.com/api/signup/add",signupDetails)
    }
    postlogin(loginDetails){
        return axios.post(USER_API_BASE_URL+"auth/login",loginDetails)
    }

    postregistration(registrationdata){
       return axios.post(USER_API_BASE_URL+"registration/", registrationdata,{
         headers: { Accept: 'application/json',       
         Authorization: `Bearer ${getToken()}` }})

    }
  

    //change in adddeal.js and editdeals.js
     privateAxios=axios.create({
        baseURL:DEALS_API_BASE_URL
    })

    createDeals(deal){
        console.log("i am in create deal")
        this.privateAxios.interceptors.request.use(config=>{
            const token=getToken()
            console.log(token)
            if(token)
            {
                config.headers.common.Authorization=`Bearer {$token}`
                return;
            }
        },error=>Promise.reject(error))
        // return axios.post(DEALS_API_BASE_URL+"/upload",deal);
    }

    getDealDetail(id){
        // console.log("i am in getdealdetail")
        return axios.get(DEALS_API_BASE_URL+"/dealdetails/"+id)
    }

    
    // createUser(user){
    //     return axios.post(USER_API_BASE_URL+"registration",user)
    // }
    
}

export default new UserService()
