import React from 'react';
import './Footer.css'
import ContactUs from './ContactUs';
import PrivacyPolicy from './PrivacyPolicy';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const Footer=()=>{

    // window.onscroll=()=>{
    // console.log(document.body.offsetHeight)
    // }
   
    return(
       
        <div className='footer'>
            <div className="container">                
            <div className='left' >
                {/* <h5>About Us</h5> */}
                <p style={{fontSize:"15px"}}>
                       
                    {/* we are making your shoping easy with hand picked deals for useful things,new release product that you dont know existed,store or brands freebies and giveaway  */}
                    Our Hand picked daily deals are meant to save you a lot on your daily useful things.we work across various platform to carefully pick deals that are great value!
                </p>               
            </div>

            {/* <div className='center'> */}
               
                {/* <form className='signup'>
                        <label >
                            Sign Up and Never Missed A Deal
                        </label>
                  
                        <input type="text" placeholder='Enter Email Address' required ></input>
                   
                        <button type='submit' style={{ padding: '8px' }}>Sign Up</button>
                    </form> */}
            {/* </div> */}
                <div className='right'> 
               
                    <h5> <a  href='/'>Home</a></h5>
                    <h5><a  href='/blog'>Blog</a></h5>
                    <h5><a  href='/ContactUs'>Contact Us</a></h5>
                    <h5 style={{color:"#fff",fontSize:"20px"}}>Follow Us</h5>                
                    <ul>                        
                        <li><a style={{fontSize:"30px"}} href="https://www.facebook.com/profile.php?id=61550555985381" target='_blank' rel="noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i> </a></li>
                        <li><a style={{fontSize:"30px"}}  href="https://www.facebook.com/groups/dinodealzandcouponz" target='_blank' rel="noreferrer"><i className="fa fa-group" aria-hidden="true"></i> </a></li>
                        <li><a style={{fontSize:"30px"}}  href="https://www.pinterest.com/Dinodealz/" target='_blank' rel="noreferrer"><i className='fa fa-pinterest'></i> </a></li>
                        <li><a style={{fontSize:"30px"}}  href="https://www.instagram.com/dinodealzandcouponz/" target='_blank' rel="noreferrer"><i className="fa fa-instagram" aria-hidden="true"></i> </a></li>
                    </ul>
                    <h5><a  href='/PrivacyPolicy'>Privacy Policy</a></h5>
                   
                </div>               
</div>
            </div>
      

    )

}
export default Footer