

import React, { useState, useEffect, Fragment } from 'react';
import UserService from '../services/UserService';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// import SEO from './SEO'

// import Card from 'react-bootstrap/Card';
import Header1 from '../component/Header1';
import './DealDetail.css';
import Footer from './Footer'
import Home2 from './Home2';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { Container } from 'reactstrap';
// import { CardBody, CardHeader, CardImg, CardSubtitle, CardTitle, Container,Col,Row } from 'reactstrap';

function DealDetail() {
  const [data, setData] = useState([]);
  let { id, commondiscription } = useParams();
  const navigate = useNavigate();
  const [url, seturl] = useState('');
  //  const[file,setFile]=useState([]);

  useEffect(() => {
    window.scrollTo(0,0)
    UserService.getDealDetail(id).then((res) => {
      setData(res.data)
      console.log(res.data)
      const blob = new Blob([`${data.dealImageByte}`], { type: 'image/jpeg' });
      // const url=URL.createObjectURL(blob)

      const file = new File([blob], 'share.jpg', { type: blob.type });

    })
  }, [id, commondiscription]);

  const gotolink = (e) => {
    navigate("/")
  }
  const onshare = async (e) => {
    //  const blog=data.dealImageByte;
    const blob = new Blob([data.imageurl], { type: 'image/jpeg' });
    // const url=URL.createObjectURL(blob)

    const file = new File([blob], 'share.jpg', { type: blob.type });
    console.log(file)
    console.log(`${data.imageurl}`)
    console.log(navigator.share)
    if (navigator.share) {
      await navigator.share({
        title: `${data.commondiscription}`,
        text: "amazing deal ",
        url: window.location.href,
        files: [file]
      }).then(() => console.log("successfully share"))
        .catch((error) => console.log('error while sharing'))
    } else {
      console.log('system does not support sharing files')
    }
  }
  // console.log(data)
  return (
    <>
    {/* <Helmet>
         <meta charset="utf-8" />
   <title>dinodealz</title>
   <meta
      property="og:description"
      content={data.commondiscription} 
    />
    <meta property='og:url' content={window.location.href} />
  
   <meta property='og:image' content={data.imageurl}/>
        </Helmet> */}

      <Container>
        
        {/* <Header1 /> */}
        <div className="myrow" >
          <div className="content">
            <div className="leftcolumn">
              <div className="pagelink">
                <Link to="/"> Home </Link> {'>'}<Link to={"/store/" + data.store}> {data.store}</Link>
              </div>
              <div className="dealsdetail">
                <div className="sociallink">
                {/* <a href="https://www.facebook.com/sharer/sharer.php?u=example.org" target="_blank">
  Share on Facebook
</a> */}
                  <FacebookShareButton
                    url={window.location.href}
                    // media={data.imageurl}
                    image={data.imageurl}                    
                    quotes={" great deal"} hashtag={"#ad"}><FacebookIcon size={32} /></FacebookShareButton>&nbsp;&nbsp;
                  <WhatsappShareButton
                    url={window.location.href}
                    image={data.imageurl}
                    quotes={" great deal"} hashtag={"#ad"}><WhatsappIcon size={32} /></WhatsappShareButton>
                    
                  {/* </div> */}
                  {/* <br /> <br /> */}

                </div>
                <div className='title'>
                  <img src={data.imageurl} style={{ width: "250px" }} alt="dealimage"></img>
                  <h1>{data.dealcommondiscription}</h1>
                </div>
                <div id="subtitle">
                  <p>We may receive a small payment from an affiliate if you click a link to purchase a product</p>
                </div>
                <div className="divider">
                </div>

                <div className="sub">
                   {
                  data.subdeal != null &&
                  data.subdeal.map(deal =>
                    <div className='subdeal'>
                      <img src={deal.imageurl} style={{ width: "250px" }} alt="dealimage"></img>
                      <div className='subdealdata'>
                        <h4><a href={deal.link} style={{ color: "black", fontSize: '25px' }} target="_blank" rel="noreferrer">{deal.title}  </a>  
                       at <a href={data.storelink} target="_blank" rel="noreferrer" style={{ color: "green" }}>{data.store}.com</a></h4>
                        <h5>As low as ${deal.price}   &nbsp; &nbsp;&nbsp;   Reg. <span id="strike">${deal.regularprice} </span>  </h5>
                        <p>{deal.discription} </p>

                        <p > {deal.promocode}</p>
                        
                        <br />
                        <p><Link to={deal.link} target="_blank" rel="noreferrer" className='buynow' >Buy Now at {data.store} &nbsp;&nbsp; &gt; </Link></p><br />
                      </div>

                    </div>
                  )
                }
                </div>     

                {/* <card>               
            
              <h3>{data.dealcommondiscription}</h3>
           
                <h5>{data.store}</h5>
            
            <img src={data.imageurl} style={{ width: "250px" }} alt="dealimage">
            </img>
            
            <hr/>               
       
          <div>
          
            {
              data.subdeal != null &&
              data.subdeal.map(deal =>

                <div className='subdeal'>
              
           
                 <img src={deal.imageurl} alt="dealimage"></img>
            
               <div>
                   <h5>< a href={deal.link} style={{color:"black", fontSize: '25px' }} target="_blank" rel="noreferrer">{deal.title}  </a>  </h5>
                    <h6 >at <a href={data.storelink} target="_blank" rel="noreferrer" style={{color:"black"}}>{data.store}.com</a></h6>
                  <b>as low as ${deal.price}    {deal.regularprice} </b> 
                 <p>{deal.discription} </p>
                  
                  <p > {deal.promocode}</p>
                <br/>
                  <Link to={deal.link} target="_blank" rel="noreferrer" className='buttonlink'>Buy Now</Link><br/>                
                  </div> 
       
               <hr/>              
               </div>)           
            }  
         </div>
         </card> */}
              </div>
            </div>

            <div className="rightcolumn1">
            </div>
          </div>
        </div>
      </Container>
    </>)
}

{/* '''''''''''''''''''''''' */ }
{/* <div className="Dealdetail">
        <br/>
      
       <Link to="/"> Home </Link> {'>'}<Link to={"/store/" + data.store}> {data.store}</Link>
          <div className='divider'></div>
          
          <div className='maintitle'>
          <FacebookShareButton
            url={window.location.href} 
           media={data.imageurl}
            quotes ={" great deal"} hashtag={"#ad"}><FacebookIcon size={32} /></FacebookShareButton><lable>  </lable>
             <WhatsappShareButton
            url={window.location.href} 
          image={data.imageurl}
            quotes ={" great deal"} hashtag={"#ad"}><WhatsappIcon size={32}/></WhatsappShareButton> */}

{/* <a href={`https://web.whatsapp.com/send?text= Please Visit ${window.location.href}`}  
rel="nofollow noopener" target="_blank"
className="share-icon"><img src={`data:image/jpeg;base64,${data.dealImageByte}`} style={{height:'36px'}}/>Share via Whatsapp</a> */}
{/* <br/> <br/> 
            
               <card>               
               
                 <h3>{data.dealcommondiscription}</h3>
              
                   <h5>{data.store}</h5>
               
               <img src={data.imageurl} style={{ width: "250px" }} alt="dealimage">
               </img>
               
               <hr/>               
          
             <div>
             
               {
                 data.subdeal != null &&
                 data.subdeal.map(deal =>
 
                   <div className='subdeal'>
                 
                  <div>
                    <img src={deal.imageurl} alt="dealimage"></img>
                  </div>
                  <div>
                      <h5>< a href={deal.link} style={{color:"black", fontSize: '25px' }} target="_blank" rel="noreferrer">{deal.title}  </a>  </h5>
                       <h6 >at <a href={data.storelink} target="_blank" rel="noreferrer" style={{color:"black"}}>{data.store}.com</a></h6>
                     <b>as low as ${deal.price}    {deal.regularprice} </b> 
                    <p>{deal.discription} </p>
                     
                     <p > {deal.promocode}</p>
                   <br/>
                     <Link to={deal.link} target="_blank" rel="noreferrer" className='buttonlink'>Buy Now</Link><br/>                
                     </div> 
          
                  <hr/>              
                  </div>)           
               }  
            </div>
            </card>
           
            </div>

         
            </div> */}



{/* <Footer/> */ }



export default DealDetail


