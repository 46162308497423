import React, { useState, useEffect, useRef } from 'react';
import UserService from '../services/UserService';
import { Link, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './deal.css';
import Header1 from './Header'
import Footer from './Footer'
import ReactLoading from "react-loading";
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { Container,ListGroup } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import prime from './prime-day-logo.webp';
import SignUp from './SignUp';
import PrimeCard from './Blog/PrimeCard'

// import './OPI.jfif' ;


// import './Home.css';


function Home2() {
  const [count, setCount] = useState(2)
  const[pagenumber,setpagenumber]=useState(0)
  const [iscompleted, setIsCompleted] = useState(false);

  const [deals, setData] = useState([]);
  const[mydeals,setmydeals]=useState([]);
  const [togel, settogel] = useState(false);
  const[categoryData,SetCategory]=useState([]);

  const [searchstring, setSearchString] = useState('prime');
  const [loading, setLoading] = useState(true);
  const mounttime = useRef(Date.now());
  const [checking,setChecking]=useState(false)
  const navigate = useNavigate();

  const goSearch = (e) => {
    e.preventDefault();
    // console.log(e.target.name)
    navigate("/search/" + e.target.name)
  }
  useEffect(() => {
    UserService.getCategory().then((res)=>SetCategory(res.data))
    UserService.getDeals(pagenumber,12).then((res) => {
      setData(res.data)
      // console.log(res.data)
      if (res.data.length !== 0) {
        settogel(true);
        setLoading(false);
        setChecking(true)
      }
    setpagenumber((pagenumber)+1)
    })
  }, []);

  const loadMore = (move) => {
    setCount((count) + 12)

    if (count >= deals.length) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }

      UserService.getDeals(pagenumber,12).then((res) => {

        if(res.data.length!==0){
           console.log(res.data)
       
      setData(deals.concat(res.data))
          console.log("deals"+deals.length)


            // console.log("length"+res.data.length)
            settogel(true);
            setLoading(false);
        }
        else{
            setChecking(false)
        }
        setpagenumber((pagenumber)+1)
   
     })
    // }, []);
  }
  function add_post(contents){
    const post=document.createElement('div');
    post.className='post';
    post.innerHTML=contents;
    document.querySelector("#data").append(post);
  }

 

  return (
    <>
      {/* <Header1 /> */}
      <Container>
        <div className="myrow" >
          
          {/* <Carousel  data-bs-theme="dark"  style={{width:"800px", height:"400px"}}>
            <Carousel.Item >
              <Link to="/myblog/primeday"><img
              className="d-block w-100"
              src="prime-day-logo.webp"
              alt="First slide"
              style={{width:"700px", height:"400px"}}
              /></Link>
        <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
           className="d-block w-100"
          src="prime-day-logo.webp"
          alt="Second slide"
          style={{width:"700px", height:"400px"}}      
     />
        <Carousel.Caption>
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
           className="d-block w-100"
          src="prime-day-logo.webp"
          alt="Third slide"
          style={{width:"700px", height:"400px"}}        
        />
        <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel> */}
    <hr></hr>
 
    <SignUp/>
    <hr/>
          <div className="content">        

            {togel ? (
              <div className="leftcolumn">
                {/* <img src="OIP.jfif"/> */}              

                <div className="dealsdata" id="data">                 
                  {                 
                  deals.map(
                      (deal, count) =>

                        <Card >

                          <Link to={'/deal/' + deal.id + '/' + ((deal.commondiscription).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")).replace(/\s+/g, '-')} style={{ marginTop: '10px' }}>
                            <Card.Img className="img" variant="top" src={deal.imageurl} style={{ marginLeft: '8%' }} />
                          </Link>

                          <Card.Body id="body">
                            <Card.Text >
                             
                              <p></p>
                              <Link to={'/deal/' + deal.id + '/' + ((deal.commondiscription).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")).replace(/\s+/g, '-')} >
                                <Card.Title id="title" style={{ color: "#171c24" }}>{deal.commondiscription}</Card.Title></Link>
                                <div >{deal.store.store}</div>
                            
                            </Card.Text>

                          </Card.Body>
                        </Card>)
                  }
                
                </div>
                <div className="loadmore"  >
                {/* <button className='btnLoadmore' onClick={loadMore} type='button'>Show More</button> */}
                  {iscompleted ? (
                    <button className='btnLoadmore' onClick={loadMore} type='button' disabled>No Deals To Show</button>
                  ) : (<button className='btnLoadmore' onClick={loadMore} type='button'>Show More</button>)}
                </div>
               
              </div>

            ) : (
              <div className="leftcolumn" style={{ height: "100vh", textAlign: "center" }}>

                <Spinner animation="border" />

              </div>

            )}
            <div className="rightcolumn">
            <ListGroup className="listgroup" style={{fontWeight:"300"}} variant='flush'>
              {
                categoryData.map((category)=> 
               <ListGroup.Item className='listgroupitem'  action href={"/category/"+category.category}>{category.category +' '+ 'deals'}  </ListGroup.Item>  
                // <a href={"/category/" + data.category} data-name={data.category} key={i}>{data.category}</a>
                )
              }     
    </ListGroup> 
    {/* <AdBanner/>              */}
          
            </div>
            
          </div>
        </div>

        {/* <Footer /> */}
      </Container>
    </>
  )
}



export default Home2;
