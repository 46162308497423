import React from 'react'
import {Link} from 'react-router-dom'
import Card from 'react-bootstrap/Card';
    import Button from 'react-bootstrap/Button';
  import '../deal.css'
  // import '../prime.js';

function PogopassCard() {
  return (
   
    <Card style={{ width: '25rem' }}>
    <Card.Img variant="top" src="/pogopass.jfif" style={{height:"75%"}}/>
    <Card.Body>
     
     <Link to="/myblog/pogopass">
     POGO PASS = ONE PASS.ONE PRICE. ONE YEAR OF FUN!
      </Link>
    
    </Card.Body>
  </Card>     
    
   
      );
    }


export default PogopassCard